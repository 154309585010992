<script>
import { mapState, mapActions } from "vuex";
import ButtonRedirect from "@/components/helpers/ButtonRedirect";
import MainDocument from "@/components/helpers/MainDocument.vue";

export default {
  name: "News",

  components: {
    ButtonRedirect,
    MainDocument,
  },

  data() {
    return {
      previousRoute: null,
    };
  },

  computed: {
    ...mapState({
      status: (state) => state.news.status,
      news: (state) => state.news.news,
    }),
  },

  methods: {
    ...mapActions({
      loadNews: "news/loadNews",
    }),

    redirect() {
      if (this.previousRoute) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "News" });
      }
    },
  },

  created() {
    if (Number.isInteger(this.$route.params.id)) {
      this.loadNews(this.$route.params.id);
    } else {
      this.$router.push({ name: "AllNews" });
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from;
    });
  },
};
</script>

<template>
  <div v-if="status === 'SUCCESS'" class="news">
    <button-redirect text="Назад" @click="redirect" />

    <h2 class="news__title">
      {{ news.title }}
    </h2>

    <p class="news__date">
      {{ new Date(news.published_at).toLocaleString() }}
    </p>

    <div class="news__image">
      <img :src="news.thumbnail" alt="" />
    </div>

    <div class="ql-editor news__text" v-html="news.content"></div>

    <div v-if="news.attachment.length > 0" class="news__attachments">
      <h3>Вложения</h3>

      <main-document
        v-for="attachment in news.attachment"
        :key="attachment.id"
        :title="attachment.original_name"
        :description="attachment.description"
        :url="attachment.url"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.news {
  padding: 0 $space-xxl;

  @media (min-width: 426px) and (max-width: 576px) {
    padding: 0 $space-xl;
  }

  @media (max-width: 425px) {
    padding: 0 $space-l;
  }

  @media (max-width: 375px) {
    padding: 0 $space-m;
  }

  .button-redirect {
    margin: $space-xl 0 12px;
  }

  &__title {
    max-width: 500px;
    margin: 0 0 $space-m;
    color: $dark-primary;
    @include title-3;
  }

  &__date {
    margin: 0 0 $space-m;
    color: $light-sixth;
    @include body-1;
  }

  &__image {
    max-width: 500px;
    margin: 0 0 $space-m;
    display: inline-flex;
    border-radius: 8px;
    overflow: hidden;

    > img {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
    }
  }

  &__text {
    max-width: 600px;
    color: $dark-primary;
    margin: 0 0 $space-xxl;
    word-wrap: anywhere;
    @include body-1;
  }

  ::v-deep img {
    width: 100%;
  }

  &__attachments {
    background-color: $background-white;
    padding: $space-xl;
    max-width: 620px;
    border-radius: $space-s;
    margin-bottom: $space-xxxl;

    // TODO: font for attachments title
    h3 {
      margin: $space-xl;
    }
  }
}
</style>
