<script>
import IconBase from "@/components/helpers/IconBase.vue";
import IconDocument from "@/components/icons/IconDocument.vue";

export default {
  components: {
    IconBase,
    IconDocument,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    url: {
      type: String,
      default: "",
    },
  },

  methods: {
    downloadAttachment(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<template>
  <div class="main-document" @click="downloadAttachment(url)">
    <div class="main-document__wrapper">
      <icon-base
        icon-name="icon-document"
        width="40"
        height="40"
        view-box-height="40"
        view-box-width="40"
        icon-fill="#212121"
      >
        <icon-document />
      </icon-base>

      <div class="main-document__description description">
        <div class="description__title">
          {{ title }}
        </div>

        <div v-if="description" class="description__subtitle">
          {{ description }}
        </div>
      </div>
    </div>

    <span class="main-document__arrow"></span>
  </div>
</template>

<style scoped lang="scss">
.main-document {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    .main-document__arrow {
      display: inline-block;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: $space-xl;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: $space-l;
  }

  .icon-base {
    margin: 0 $space-l 0 0;
    min-width: 40px;
  }

  &__description {
    max-width: 300px;
    @include body-1;
  }

  .description {
    &__title {
      color: $dark-primary;
      @include body-1;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__subtitle {
      @include caption-1;
      color: $dark-fifth;
      margin-top: $space-xxs;
    }
  }

  &__arrow {
    display: none;
    width: 8px;
    height: 8px;
    background: $light-sixth;
    mask: url("../../assets/icons/arrow_bold.svg");
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}
</style>
